<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    v-model="username"
                    placeholder="Username"
                    autocomplete="username email"
                    v-on:keyup.enter="focusNext"
                    id ="input"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    v-model="password"
                    id ="input"
                    autocomplete="curent-password"
                    v-on:keyup.enter="focusNext"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>

                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" @click="login" class="px-4">Login</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Forgot password?</CButton>
                      <!--CButton color="link" class="d-lg-none">Register now!</CButton-->
                    </CCol>
                  </CRow><br/>
                  <CRow>
                   <CCol col="12" class="text-left">
                   <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                   </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <!--CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard-->
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

import AuthService from '@/api/AuthService.js';

    export default {
      name: 'Login',
      data() {
        return {
          username: '',
          ipAddress: '',
          password: '',
          msg: '',
          seen: false,
          color: ''
        };
      },

      methods: {
        focusNext(e) {
            const inputs = Array.from(e.target.form.querySelectorAll('input[id="input"]'));
            const index = inputs.indexOf(e.target);

            if(index === 1){
              this.login();
            } else if (index < inputs.length) {
              inputs[index + 1].focus();
            }
        },

        async submitLogin() {
          try {
            const credentials = {
              userName: this.username,
              password: this.password
            };
            const resp  = await AuthService.checkLogin(credentials);
            const token = resp.headers.authorization;
            const user  = this.username;
            const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];

            let roles = resp.data.roles; var sa = false;
            for (const val of roles) {
              if(val==="ROLE_SUPER_ADMIN"){ sa = true; }
            }
            localStorage.setItem('sa', sa);
            localStorage.setItem('roles', roles);

            this.$store.dispatch('login', { token, user });
            if (LOCAL_DOMAINS.includes(window.location.hostname)){
              localStorage.setItem('isAuthenticated', true);
              this.$router.push('/dashboard');
            } else {
              if(resp.status=="200") {
                try {
                  const resp2 = await AuthService.sentOtpToken();
                  if(resp2.status=='OK'){
                    this.msg   = 'Token has been sent to your email';
                    this.color = 'success';  this.seen  = true;
                    setTimeout( () => this.$router.push({ path: '/auth/otp'}), 2000);
                  }
                } catch (err) { console.log(err); }
              }
            }

          } catch (error) {

            const paramsExpiry = {
              userName: this.username,
              existingPassword: this.password
            };
            const resp2  = await AuthService.checkPasswordExpiry(paramsExpiry);
            if(resp2.status == 200){
              if(resp2.hasOwnProperty('data') && resp2.data.message == "expired"){
                localStorage.setItem('hash_pass', btoa(this.password));
                this.$router.push({ path: '/auth/password-expiry', query: { user: this.username } });
              }
            }
            this.msg   = 'Invalid Username or Password!';
            this.color = 'warning'; this.seen  = true;
          }
        },

        async login() {
          const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];
          if (!LOCAL_DOMAINS.includes(window.location.hostname)) {
            AuthService.checkIps(this.username).then(resp => {
              console.log(resp);
              this.submitLogin();
            }).catch((error) => {
              console.log(`error : `);
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.hasOwnProperty('data')) {
                  let error_data = error.response.data;
                  // this.msg = error_data.message;
                  this.msg = 'Your location is out of bound';
                  this.color = 'warning';
                  this.seen = true;
                  console.log(error_data);
                }
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser
                // and an instance of http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                this.msg = 'Invalid IP Address!';
                this.color = 'warning';
                this.seen = true;
                console.log('Error', error.message);
              }
            });
          } else {
            await this.submitLogin();
          }
        }
      }
    };

</script>
